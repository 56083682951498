import { getDefaultOptions } from './auth';
import type { UpdateContactPayload } from './contact';

interface DispatchAction {
  type: string;
  payload: UpdateContactPayload;
}

//
// ACTIONS
//

export const FIRST_TIME_BORROWER_ANSWERED = 'FIRST_TIME_BORROWER_ANSWERED';
export const SOLO_BORROWER_ANSWERED = 'SOLO_BORROWER_ANSWERED';
export const WHEN_BUYING_ANSWERED = 'WHEN_BUYING_ANSWERED';
export const EMAIL_ANSWERED = 'EMAIL_ANSWERED';
export const NAME_ANSWERED = 'NAME_ANSWERED';
export const LOGGED_IN = 'LOGGED_IN';
export const CONSENTS_ANSWERED = 'LOGGED_IN';
export const ADDRESS_ANSWERED = 'ADDRESS_ANSWERED';
export const RENT_ANSWERED = 'RENT_ANSWERED';
export const INCOME_ANSWERED = 'INCOME_ANSWERED';

export const WHERE_BUYING_ANSWERED = 'WHERE_BUYING_ANSWERED';
export const AGENT_ANSWERED = 'AGENT_ANSWERED';
export const SOFT_CREDIT_REQUESTED = 'SOFT_CREDIT_REQUESTED';
export const DOWN_PAYMENT_ANSWERED = 'DOWN_PAYMENT_ANSWERED';
export const ASSETS_REQUESTED = 'ASSETS_REQUESTED';
export const EMPLOYMENT_REQUESTED = 'EMPLOYMENT_REQUESTED';
export const UNDERWRITING_REQUESTED = 'UNDERWRITING_REQUESTED';

//
// STATES
//

export const INIT = 'INIT';

export const FIRST_TIME_BORROWER = 'FIRST_TIME_BORROWER';
export const SOLO_BORROWER = 'SOLO_BORROWER';
export const SERVICED_AREA = 'SERVICED_AREA';
export const SOFT_CREDIT_REPORT_ORDERED = 'SOFT_CREDIT_REPORT_ORDERED';
export const HAS_SOFT_CREDIT_REPORT = 'HAS_SOFT_CREDIT_REPORT';
export const FICO_IS_MINIMUM = 'FICO_IS_MINIMUM';
export const NO_BANKRUPTCY = 'NO_BANKRUPTCY';
export const NO_DELINQUENCIES = 'NO_DELINQUENCIES';
export const AT_LEAST_THREE_OPEN_TRADELINES = 'AT_LEAST_THREE_OPEN_TRADELINES';
export const TWO_YEAR_RESIDENTIAL_HISTORY = 'TWO_YEAR_RESIDENTIAL_HISTORY';
export const PAYING_RENT = 'PAYING_RENT';
export const HAS_LOAN = 'HAS_LOAN';
export const ASSETS_ORDERED = 'ASSETS_ORDERED';
export const HAS_ASSETS = 'HAS_ASSETS';
export const THREE_PERCENT_DOWN = 'THREE_PERCENT_DOWN';
export const EMPLOYMENT_DATA_ORDERED = 'EMPLOYMENT_DATA_ORDERED';
export const HAS_EMPLOYMENT_DATA = 'HAS_EMPLOYMENT_DATA';
export const HAS_TWELVE_MONTH_WORK_HISTORY = 'HAS_TWELVE_MONTH_WORK_HISTORY';
export const DTI_LESS_THAN_THIRTY = 'DTI_LESS_THAN_THIRTY';
export const SALARIED_INCOME = 'SALARIED_INCOME';
export const HAS_PRICING = 'HAS_PRICING';
export const UNDERWRITING_ORDERED = 'UNDERWRITING_ORDERED';
export const HAS_UNDERWRITING = 'HAS_UNDERWRITING';
export const PRE_APPROVED = 'PRE_APPROVED';

export const startState = async () => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  const res = await $fetch(
    config.public.apiBaseUrl + '/state/start',
    { ...options, method: 'POST' },
  );
  return res;
};

export const dispatch = async (action: DispatchAction) => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  const res = await $fetch(
    config.public.apiBaseUrl + '/state/dispatch',
    { ...options, method: 'POST', body: action },
  );
  return res;
};

export const dispatchAsync = async (action: DispatchAction) => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  const res = await $fetch(
    config.public.apiBaseUrl + '/state/dispatch-async',
    { ...options, method: 'POST', body: action },
  );
  return res;
};

interface State {
  [key: string]: boolean;
}

export const getState = async () => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  const res:{ state:State } = await $fetch(
    config.public.apiBaseUrl + '/state',
    { ...options, method: 'GET' }, // using POST b/c it may kick off events in some cases
  );
  const { state } = res;
  const reasons = Object.keys(state).filter(key => state[key] === false);
  return { state, reasons };
};

const noCriteria = () => true;

// Initiates polling, will stop after:
// * The criteria lambda is satisfied OR
// * The max amount of repeats have happend
export const pollState = async (
  {
    criteria = noCriteria,
    maxRepeat = 12,
    sleepSeconds = 5,
  } : {
    criteria: (res:{ state:State, reasons:string[] }) => boolean,
    maxRepeat: number,
    sleepSeconds: number,
  },
) => {
  const stopWhen = (result:{ state:State, reasons:string[] }) => criteria(result);
  const response = await poll(
    () => getState(),
    stopWhen,
    sleepSeconds * 1000,
    maxRepeat,
  );
  return response;
};

export const poll = async function (
  fn: () => Promise<{ state:State, reasons:string[] }>,
  fnCondition: (res:{ state:State, reasons:string[] }) => boolean,
  ms: number,
  maxCount = 15,
) {
  let result = await fn();
  let currentCount = 0;
  while (currentCount < maxCount && !(await fnCondition(result))) {
    currentCount += 1;
    await wait(ms);
    result = await fn();
  }
  if (currentCount === maxCount) {
    throw new Error('Polling timeout');
  }
  return result;
};

export const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
